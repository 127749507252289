import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './EarlyDiagnosisBanner.module.css';

class EarlyDiagnosisBannerImage extends React.PureComponent {
    render(){
        return (
            <Img
                fluid={this.props.earlyDiagnosisBannerImage.childImageSharp.fluid}
            />
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                earlyDiagnosisBannerImage: file(relativePath: { eq: "earlyDiagnosis/early-diagnosis-banner.png" }){
                    childImageSharp {
                        fluid(maxWidth:1440){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => {
            // let isIE = /*@cc_on!@*/false || !!document.documentMode;
            let isIE = false;
            
            if( isIE ){
                return (
                    <div className={style.HealthyHeartsBanner}>
                        <div className={style.HealthyHeartsImageContainer}>
                            <EarlyDiagnosisBannerImage earlyDiagnosisBannerImage={data.earlyDiagnosisBannerImage} className={style.BannerImage} />
                        </div>
                        <div className={style.HealthyHeartsTitleContainerIE}>
                            <div className={style.EDTitleTable}>
                                <div className={style.EDTitleTableCell}>
                                    <p className={style.HealthyHeartsTitle}>Benefits of an early diagnosis</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.HealthyHeartsBreadcrumbsContainer}>
                            <Link to="/" className={style.HealthyHeartsBreadcrumb}>Home</Link>&nbsp;>&nbsp;
                            Early diagnosis
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={style.HealthyHeartsBanner}>
                        <div className={style.HealthyHeartsImageContainer}>
                            <EarlyDiagnosisBannerImage earlyDiagnosisBannerImage={data.earlyDiagnosisBannerImage} className={style.BannerImage}/>
                        </div>
                        <div className={style.HealthyHeartsTitleContainer}>
                            <p className={style.HealthyHeartsTitle}>Benefits of an early diagnosis</p>
                        </div>
                        <div className={style.HealthyHeartsBreadcrumbsContainer}>
                            <Link to="/" className={style.HealthyHeartsBreadcrumb}>Home</Link>&nbsp;>&nbsp;
                            Early diagnosis
                        </div>
                    </div>
                )
            }}
        }
    />
)
