import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/common/Layout.jsx';
import EarlyDiagnosisBanner from '../components/early-diagnosis/EarlyDiagnosisBanner.jsx';
import { Helmet } from 'react-helmet';

class EarlyDiagnosis extends React.PureComponent {
    render(){
        return (
            <Layout>
                <Helmet>
                    <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="description" content="Read now for heart information in dogs." />
                    <meta name="robots" content="index, follow" />
                    <meta property="og:title" content="Benefits of an early diagnosis - Boehringer Ingelheim" />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content="Read now for heart information in dogs." />
                    <meta property="og:locale" content="en_AU" />

                    <title>Benefits of an early diagnosis - Boehringer Ingelheim 2019</title>
                </Helmet>
                <EarlyDiagnosisBanner/>
                <div className="EarlyDiagnosisMainContainer">
                    <div className="ContentContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionTitle">What does a diagnosis of heart disease mean?</p>
                                <p className="SectionText">Staying informed and knowing what signs to look for is important because early diagnosis and treatment of heart disease may help significantly prolong and improve your dog's life.<sup>1,2,3</sup></p>
                                <p className="SectionText">In the early stages of heart disease, your dog may show no visible signs of being unwell; however, as time progresses, so can their condition.</p>
                                <p className="SectionTitle">Benefits of early diagnosis</p>
                                <p className="SectionText">The outlook for dogs with heart disease has improved significantly in recent years, with emphasis shifting to early treatment for optimal benefits.</p>
                                <p className="SectionText HighlightedSection">Scientific research shows that effective treatment started early, before heart disease signs become apparent (Stage B2 heart disease<sup>&#42;</sup>), can help ensure that dogs continue to enjoy a good quality of life and can extend their length of life.<sup>3</sup></p>
                                <p className="SectionText">&#42;For more information on the stages of heart disease see <Link to="/heart-disease/stages-of-heart-disease">Stages of heart disease</Link></p>
                                <p className="SectionText">There are tests your vet can perform to identify your dog’s stage of heart disease, that will help them plan the right course of action, for example:</p>
                                <ul className="SectionList">
                                    <li className="SectionListElement">&bull; listening to the heart through a stethoscope</li>
                                    <li className="SectionListElement">&bull; assessing the heart, lungs, and major blood vessels on an x-ray</li>
                                    <li className="SectionListElement">&bull; assessing the electrical rhythm of the heart through an electrocardiogram</li>
                                    <li className="SectionListElement">&bull; watching the heart at work on an ultrasound</li>
                                </ul>
                                <p className="SectionText">The benefits of early diagnosis and treatment, even before the onset of signs, makes regular heart checks in dogs more important than ever before.</p>
                            </div>
                            <div className="ReferenceContainer">
                                <div className="ReferenceText">References:</div>
                                <div className="ReferenceText">1. Häggström J., et al. (2008) Effect of pimobendan or benazepril hydrochloride on survival times in dogs with congestive heart failure caused by naturally occurring myxomatous mitral valve disease. The QUEST study. <em>J Vet Intern Med.</em>;22(5):1124&ndash;1135.</div>
                                <div className="ReferenceText">2. Lombard CW., et al; for the VetSCOPE Study. (2006) Clinical efficacy of pimobendan versus benazepril for the treatment of acquired atrioventricular valvular disease in dogs. <em>J Am Anim Hosp Assoc.</em>;42(4):249&ndash;261.</div>
                                <div className="ReferenceText">3. Boswood A., et al. (2016) Effect of pimobendan in dogs with preclinical myxomatous mitral valve disease and cardiomegaly: the EPIC Study – A randomized clinical trial. <em>J Vet Intern Med.</em>;30(6):1765&ndash;1779.</div>
                            </div>
                        </div>
                    </div>
                </div>                
            </Layout>
        )
    }
}

export default EarlyDiagnosis;
